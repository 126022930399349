import { BrowserRouter, Route, Switch } from "react-router-dom";

import "./App.less";
// import Graph from "./components/Graph/Graph";
import { getUserFromStorage } from "./services/userService";
import React, { Suspense, useEffect, useMemo, useState } from "react";
import UserContext from "./contexts/UserContext";
import NavContext from "./contexts/NavContext";
import ThemeContext from "./contexts/ThemeContext";
import ReactLoading from "react-loading";
import GuardedAdminRoute from "./components/GuardedAdminRoute/GuardAdminRoute";
import GuardedRoute from "./components/GuardRoute/GuardRoute";
import LoginScreen from "./screens/LoginScreen/LoginScreen";
import SignUpScreen from "./screens/SignUpScreen/SignUpScreen";
import AdminWaitingMessageScreen from "./screens/AdminWaitingMessageScreen/AdminWaitingMessageScreen";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const AdminChangePasswordScreen = React.lazy(() =>
  import("./screens/AdminChangePasswordScreen/AdminChangePasswordScreen")
);
// const GuardedAdminRoute = React.lazy(() => import('./components/GuardedAdminRoute/GuardAdminRoute'));
// const GuardedRoute = React.lazy(() => import('./components/GuardRoute/GuardRoute'));
const AdminSentMessageScreen = React.lazy(() =>
  import("./screens/AdminSentMessageScreen/AdminSentMessageScreen")
);
const AdminCodesScreen = React.lazy(() =>
  import("./screens/AdminCodesScreen/AdminCodesScreen")
);
const AdminHomeScreen = React.lazy(() =>
  import("./screens/AdminHomeScreen/AdminHomeScreen")
);
const AdminUsersScreen = React.lazy(() =>
  import("./screens/AdminUsersScreen/AdminUsersScreen")
);
const ConferenceScreen = React.lazy(() =>
  import("./screens/ConferenceScreen/ConferenceScreen")
);
const AddConferenceScreen = React.lazy(() =>
  import("./screens/ConferenceScreen/AddConferenceScreen")
);
const AuthHomeScreen = React.lazy(() =>
  import("./screens/HomeScreen/AuthHomeScreen")
);
// const LoginScreen = React.lazy(() => import('./screens/LoginScreen/LoginScreen'));
const RatingScreen = React.lazy(() =>
  import("./screens/RatingScreen/RatingScreen")
);
const ShortCodesGraphsScreen = React.lazy(() =>
  import("./screens/ShortCodesGraphsScreen/ShortCodesGraphsScreen")
);
// const SignUpScreen = React.lazy(() => import('./screens/SignUpScreen/SignUpScreen'));
const ProfileScreen = React.lazy(() =>
  import("./screens/ProfileScreen/ProfileScreen")
);
const RedirectScreen = React.lazy(() =>
  import("./screens/RedirectScreen/RedirectScreen")
);
const PollAddScreen = React.lazy(() =>
  import("./screens/PollScreen/PollAddScreen")
);
const PollListScreen = React.lazy(() =>
  import("./screens/PollScreen/PollListScreen")
);
const AdminPollManage = React.lazy(() =>
  import("./screens/AdminPollManage/AdminPollManage")
);
const AdminPollDetail = React.lazy(() =>
  import("./components/AdminPoll/AdminPollDetail")
);
const PollResultScreen = React.lazy(() =>
  import("./screens/PollScreen/PollResultScreen")
);
const AdminRatingScreen = React.lazy(() =>
  import("./screens/AdminRatingScreen/AdminRatingScreen")
);
const AdminPollAudio = React.lazy(() =>
  import("./components/AdminPoll/AdminPollAudio")
);
const LandingScreen = React.lazy(() =>
  import("./screens/LandingScreen/LandingScreen")
);
const ForgotPasswordScreen = React.lazy(() =>
  import("./screens/ForgotPasswordScreen/ForgotPasswordScreen")
);
const MessageAllScreen = React.lazy(() =>
  import("./screens/MessageScreen/MessageAllScreen")
);
const IncomingMessageScreen = React.lazy(() =>
  import("./screens/MessageScreen/IncomingMessageScreen")
);
const OTPVerificationScreen = React.lazy(() =>
  import("./screens/OTPVerificationScreen/OTPVerificationScreen")
);
const AudioScriptsScreen = React.lazy(() =>
  import("./screens/AudioScriptsScreen/AudioScriptsScreen")
);
const AdminAudioScriptsScreen = React.lazy(() =>
  import("./screens/AdminAudioScriptScreen/AdminAudioScriptScreen")
);
const UserResponseScreen = React.lazy(() =>
  import("./screens/UserCommentScreen/UserCommentScreen")
);
const ShortCodeConfig = React.lazy(() =>
  import("./components/ShortCodeConfig/ShortCodeConfig")
);
const ShortCodeConfigScreen = React.lazy(() =>
  import("./screens/ShortCodeConfigScreen/ShortCodeConfigScreen")
);
const CategoryConfigScreen = React.lazy(() =>
  import("./screens/CategoryConfigScreen/CategoryConfigScreen")
);
const CustomerInfoScreen = React.lazy(() =>
  import("./screens/CustomerInfoScreen/CustomerInfoScreen")
);
const MessageScreen = React.lazy(() =>
  import("./screens/MessageScreen/MessageScreen")
);
const BulkMessageScreen = React.lazy(() =>
  import("./screens/BulkMessageScreen/BulkMessageScreen")
);

const AudioUploadScreen = React.lazy(() =>
  import("./screens/AudioUploadScreen/AudioUploadScreen")
);

const ManageAudioScreen = React.lazy(() =>
  import("./screens/ManageAudioScreen/ManageAudioScreen")
);

const AudioUploadLogsScreen = React.lazy(() =>
  import("./screens/AudioUploadLogsScreen/AudioUploadLogsScreen")
);

const AddPhoneToCategoryScreen = React.lazy(() =>
  import("./screens/AddPhoneToCategoryScreen/AddPhoneToCategoryScreen")
);

const CategoryPhoneNumberListScreen = React.lazy(() =>
  import(
    "./screens/CategoryPhoneNumberListScreen/CategoryPhoneNumberListScreen"
  )
);
const ApiTokenScreen = React.lazy(() =>
  import("./screens/ApiTokenScreen/ApiTokenScreen")
);
const OTPVerificationEmailScreen = React.lazy(() =>
  import("./screens/OTPVerificationScreen/OTPVerificationEmailScreen")
);
const PageNotFoundScreen = React.lazy(() =>
  import("./screens/404/PageNotFoundScreen")
);
const CompanyDashboardScreen = React.lazy(() =>
  import("./screens/CompanyDashboardScreen/CompanyDashboardScreen")
);
const CompanyReportScreen = React.lazy(() =>
  import("./screens/CompanyReportScreen/CompanyReportScreen")
);
const ManageKeyScreen = React.lazy(() =>
  import("./screens/ManageKeyScreen/ManageKeyScreen")
);
const EditIncommingScreen = React.lazy(() =>
  import("./screens/ManageKeyScreen/EditIncommingScreen")
);
const AudioMessageDetailScreen = React.lazy(() =>
  import("./screens/AudioMessageDetailScreen/AudioMessageDetailScreen")
);

const ManageShortCodeScreen = React.lazy(() =>
  import("./screens/ManageShortCodeScreen/ManageShortCodeScreen")
);
const EditOutgoingScreen = React.lazy(() =>
  import("./screens/ManageKeyScreen/EditOutgoingScreen")
);
const ForgotPasswordVerficationScreen = React.lazy(() =>
  import("./screens/ForgotPasswordScreen/ForgotPasswordScreen")
);
const PasswordResetVerficationScreen = React.lazy(() =>
  import(
    "./screens/PasswordResetVerficationScreen/PasswordResetVerficationScreen"
  )
);
const PasswordResetScreen = React.lazy(() =>
  import("./screens/PasswordResetScreen/PasswordResetScreen")
);
const AdminUserShortcodesScreen = React.lazy(() =>
  import("./screens/AdminCodesScreen/AdminUserShortcodesScreen")
);
const CallLogScreen = React.lazy(() =>
  import("./screens/CallLogScreen/CallLogScreen")
);
const CustomerIvrLanguageScreen = React.lazy(() =>
  import("./screens/CustomerIvrLanguageScreen/CustomerIvrLanguageScreen")
);
const WaitingMessageScreen = React.lazy(() =>
  import("./screens/MessageScreen/WaitingMessageScreen")
);
const VoiceRecordListScreen = React.lazy(() =>
  import("./screens/VoiceRecordListScreen/VoiceRecordListScreen")
);

const AdminManageKeysScreen = React.lazy(() =>
  import("./screens/AdminCodesScreen/AdminManageKeysScreen")
);
const UserCommentScreen = React.lazy(() =>
  import("./screens/UserCommentScreen/UserCommentScreen")
);
const ChangePasswordScreen = React.lazy(() =>
  import("./screens/ChangePasswordScreen/ChangePasswordScreen")
);
const IncomingKeyScreen = React.lazy(() =>
  import("./screens/ManageKeyScreen/IncomingKeyScreen")
);
const OutgoingKeyScreen = React.lazy(() =>
  import("./screens/ManageKeyScreen/OutgoingKeyScreen")
);
const UserApprovementScreen = React.lazy(() =>
  import("./screens/UserApprovementScreen/UserApprovementScreen")
);
const OTPVerficationSignUpScreen = React.lazy(() =>
  import("./screens/OTPVerficationSignUpScreen/OTPVerficationSignUpScreen")
);
const WatingApprovalScreen = React.lazy(() =>
  import("./screens/WatingApprovalScreen/WatingApprovalScreen")
);
const AdminBalanceScreen = React.lazy(() =>
  import("./screens/AdminBalanceScreen/AdminBalanceScreen")
);
const TransactionLogScreen = React.lazy(() =>
  import("./screens/TransactionLogScreen/TransactionLogScreen")
);
const AdminShortCodeEditScreen = React.lazy(() =>
  import("./screens/AdminShortCodeEditScreen/AdminShortCodeEditScreen")
);
const Assign_IVR_ShortCodeScreen = React.lazy(() =>
  import("./screens/Assign_IVR_ShortCodeScreen/Assign_IVR_ShortCodeScreen")
);
const DeactivateUserScreen = React.lazy(() =>
  import("./screens/DeactivateUserScreen/DeactivateUserScreen")
);
const HistoryLogScreen = React.lazy(() =>
  import("./screens/HistoryLogScreen/HistoryLogScreen")
);
const AdminHistoryLogScreen = React.lazy(() =>
  import("./screens/AdminHistoryLogScreen/AdminHistoryLogScreen")
);
const CSVCategoryScreen = React.lazy(() =>
  import("./screens/CSVCategoryScreen/CSVCategoryScreen")
);

const AdminReportScreen = React.lazy(() =>
  import("./screens/AdminReportScreen/AdminReportScreen")
);
const SMSCompaignScreen = React.lazy(() =>
  import("./screens/SMSCompaignScreen/SMSCompaignScreen")
);
const OBDCompaignScreen = React.lazy(() =>
  import("./screens/OBDCompaignScreen/OBDCompaignScreen")
);
const AudioMessageScreen = React.lazy(() =>
  import("./screens/AudioMessageScreen/AudioMessageScreen")
);
const Footer = React.lazy(() => import("./components/Footer/Footer"));

// import AdminGraph from "./components/AdminGraph/AdminGraph";

function Routing() {
  return (
    <div>
      <Suspense
        fallback={
          <div className="fixed top-1/2 w-full flex items-center justify-center">
            <ReactLoading type="cylon" color="black" />
          </div>
        }
      >
        {/* <Route path="/" exact component={LandingScreen} /> */}
        {/* <Route path="/forgot" exact component={ForgotPasswordScreen} /> */}

        {/* <Route
                    path="/verificationPhone"
                    exact
                    component={OTPVerificationEmailScreen}
                  /> */}

        {/* <Route
                    path="/userApprovement"
                    exact
                    component={UserApprovementScreen}
                  />

                  <Route
                    path="/new-password"
                    exact
                    component={PasswordResetScreen}
                  />

                  <Route
                    path="/verification"
                    exact
                    component={PasswordResetVerficationScreen}
                  /> */}

        {/* <Route
                    path="/signupVerification"
                    exact
                    component={OTPVerficationSignUpScreen}
                  /> */}

        {/* <GuardedRoute path="/graph" component={Graph} /> */}

        <GuardedRoute exact path="/profile" component={ProfileScreen} />
        <GuardedRoute exact path="/rating" component={RatingScreen} />
        {/* <GuardedRoute path="/comments" component={CommentScreen} /> */}

        <GuardedRoute
          exact
          path="/short_codes_graphs"
          component={ShortCodesGraphsScreen}
        />

        <GuardedRoute
          exact
          path="/category_config"
          component={CategoryConfigScreen}
        />

        <GuardedRoute
          exact
          path="/change_password"
          component={ChangePasswordScreen}
        />

        <GuardedRoute
          exact
          path="/incomming_key/:id"
          component={EditIncommingScreen}
        />
        <GuardedRoute
          exact
          path="/outgoing_key/:id"
          component={EditOutgoingScreen}
        />
        {/* <GuardedRoute
                    path="/customer_info"
                    component={CustomerInfoScreen}
                  /> */}
        <GuardedRoute
          exact
          path="/bulk_message"
          component={BulkMessageScreen}
        />

        <GuardedRoute
          exact
          path="/dial-outbound"
          component={AudioUploadScreen}
        />

        <GuardedRoute
          exact
          path="/manage-audio"
          component={ManageAudioScreen}
        />

        <GuardedRoute
          exact
          path="/audio-upload-log"
          component={AudioUploadLogsScreen}
        />

        <GuardedRoute
          exact
          path="/incomming_key"
          component={IncomingKeyScreen}
        />

        <GuardedRoute
          exact
          path="/sms-compaign"
          component={SMSCompaignScreen}
        />

        <GuardedRoute
          exact
          path="/csv-category"
          component={CSVCategoryScreen}
        />

        <GuardedRoute
          exact
          path="/Obd-compaign"
          component={OBDCompaignScreen}
        />
        <GuardedRoute
          exact
          path="/audio-message"
          component={AudioMessageScreen}
        />

        <GuardedRoute
          exact
          path="/audio-message/:batchId"
          component={AudioMessageDetailScreen}
        />

        <GuardedRoute
          exact
          path="/outgoing_key"
          component={OutgoingKeyScreen}
        />

        <GuardedRoute exact path="/history_log" component={HistoryLogScreen} />

        <GuardedRoute
          exact
          path="/dashboard"
          component={CompanyDashboardScreen}
        />

        <GuardedRoute exact path="/report" component={CompanyReportScreen} />

        <GuardedRoute
          exact
          path="/categoryConfig/addPhoneToCategory"
          component={AddPhoneToCategoryScreen}
        />

        <GuardedRoute
          exact
          path="/categoryConfig/CategortyPhoneNumberList"
          component={CategoryPhoneNumberListScreen}
        />

        <GuardedRoute
          exact
          path="/short_codes"
          component={ShortCodeConfigScreen}
        />

        <GuardedRoute
          exact
          path="/manage_short_codes"
          component={ManageShortCodeScreen}
        />

        <GuardedRoute
          exact
          path="/assign_ivr_short_codes"
          component={Assign_IVR_ShortCodeScreen}
        />

        <GuardedRoute
          exact
          path="/user/summary"
          component={TransactionLogScreen}
        />
        <GuardedRoute exact path="/poll/result" component={PollResultScreen} />
        <GuardedRoute exact path="/poll/add" component={PollAddScreen} />
        <GuardedRoute exact path="/poll" component={PollListScreen} />
        <GuardedRoute
          exact
          path="/conference/add"
          component={AddConferenceScreen}
        />
        <GuardedRoute
          exact
          path="/incomingmessage"
          component={IncomingMessageScreen}
        />
        <GuardedRoute exact path="/sentmessage" component={MessageAllScreen} />
        <GuardedRoute
          exact
          path="/waitingmessage"
          component={WaitingMessageScreen}
        />

        <GuardedRoute
          exact
          path="/voice-record-list"
          component={VoiceRecordListScreen}
        />

        <GuardedRoute exact path="/call_log" component={CallLogScreen} />

        <GuardedRoute
          exact
          path="/ctmr-ivr-lng"
          component={CustomerIvrLanguageScreen}
        />

        <GuardedRoute exact path="/api-key" component={ApiTokenScreen} />

        <GuardedRoute exact path="/message" component={MessageScreen} />
        <GuardedRoute exact path="/conference" component={ConferenceScreen} />
        <GuardedRoute exact path="/redirect" component={RedirectScreen} />
        <GuardedRoute
          exact
          path="/user_comment"
          component={UserCommentScreen}
        />
        {/* <GuardedAdminRoute
                    exact
                    path="/admin/poll/detail"
                    component={AdminPollDetail}
                  />
                  <GuardedAdminRoute
                    exact
                    path="/admin/poll/upload"
                    component={AdminPollAudio}
                  /> */}
        {/* <GuardedAdminRoute
                    exact
                    path="/admin/poll"
                    component={AdminPollManage}
                  />
                  <GuardedAdminRoute
                    path="/admin/user/:userId/shortcodes"
                    component={AdminUserShortcodesScreen}
                  /> */}
        {/* <GuardedAdminRoute
                    path="/admin/user/:userId/manageKey"
                    component={AdminManageKeysScreen}
                  />

                  <GuardedAdminRoute
                    path="/admin/user/:userId/sentMessage"
                    component={AdminSentMessageScreen}
                  /> */}

        {/* <GuardedAdminRoute
                    path="/admin/user/:userId/waitingMessage"
                    component={AdminWaitingMessageScreen}
                  />


                  <GuardedAdminRoute
                    path="/admin/user/:userId/history_log"
                    component={AdminHistoryLogScreen}
                  />

                  <GuardedAdminRoute
                    path="/admin/user/:userId/report"
                    component={AdminReportScreen}
                  /> */}

        {/* <GuardedAdminRoute
                    path="/admin/change_password"
                    component={AdminChangePasswordScreen}
                  />

                  <GuardedAdminRoute
                    path="/admin/user/:userId/add-balance"
                    component={AdminBalanceScreen}
                  />
                  <GuardedAdminRoute
                    exact
                    path="/admin/rating"
                    component={AdminRatingScreen}
                  /> */}
        {/* <GuardedAdminRoute
                    path="/admin/short_codes/:id"
                    component={AdminShortCodeEditScreen}
                  />

                  <GuardedAdminRoute
                    path="/admin/users"
                    component={AdminUsersScreen}
                  /> */}

        {/* <GuardedAdminRoute
                    path="/admin/waiting-users"
                    component={WatingApprovalScreen}
                  />
                  <GuardedAdminRoute
                    path="/admin/Deactivate-users"
                    component={DeactivateUserScreen}
                  /> */}
        {/* <GuardedAdminRoute
                    path="/admin/short_codes"
                    component={AdminCodesScreen}
                  /> */}
        {/* <GuardedAdminRoute
                    path="/admin/audio_files"
                    exact
                    component={AdminAudioScriptsScreen}
                  /> */}
        {/* <GuardedAdminRoute exact path="/admin" component={AdminHomeScreen} /> */}

        <GuardedRoute
          path="/audio_files"
          exact
          component={AudioScriptsScreen}
        />
      </Suspense>
    </div>
  );
}

export default Routing;
